import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './main-component/home/home.component';
import { ApplicationComponent } from './main-component/application/application.component';
import { I18nPageComponent } from '@cosmobile/ng-i18n';

const routes: Routes = [
  {
    path: ':lang',
    component: HomeComponent,
    data: {'limit': 3},
  },
  {
    path: ':lang/applications',
    component: HomeComponent
  },
  {
    path: ':lang/application/:id',
    component: ApplicationComponent,
  },  
  { path: '',
    component: I18nPageComponent,
    pathMatch: 'full'
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
