<div class="bigger-container">
  <div class="title-form-container">
    <div class="title-container">
      <h2>{{ "SUBMIT_APPLICATION" | translate }}</h2>
    </div>
    <form
      class="form-container"
      [formGroup]="profileForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="firstName">{{ "FIRST_NAME" | translate }}*</mat-label>
          <input
            matInput
            id="firstName"
            type="text"
            formControlName="firstName"
          />
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="lastName">{{ "LAST_NAME" | translate }}*</mat-label>
          <input
            matInput
            id="lastName"
            type="text"
            formControlName="lastName"
          />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="emailAddress">Email*</mat-label>
          <input
            matInput
            id="emailAddress"
            type="email"
            formControlName="emailAddress"
          />
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="mobilePhone" required
            >{{ "CELL_NUMBER" | translate }}*</mat-label
          >
          <input
            matInput
            id="mobilePhone"
            type="tel"
            formControlName="mobilePhone"
          />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="linkedInUrl"
            >{{ "LINKEDIN" | translate }}**</mat-label
          >
          <input
            matInput
            id="linkedInUrl"
            type="text"
            formControlName="linkedInUrl"
          />
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="file">{{ "RESUME" | translate }}**</mat-label>
          <ngx-mat-file-input
            matInput
            id="file"
            formControlName="file"
            type="file"
            name="upload"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
          ></ngx-mat-file-input>
        </mat-form-field>
        <mat-form-field
          class="example-full-width"
          appearance="legacy"
          floatLabel="never"
        >
          <mat-label for="file">{{ "LETTER" | translate }}**</mat-label>
          <ngx-mat-file-input
            matInput
            id="file2"
            formControlName="file2"
            type="file"
            name="upload2"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
          ></ngx-mat-file-input>
        </mat-form-field>
      </div>
      <div class="form-row privacy-container">
        <mat-checkbox
          type="checkbox"
          required
          formControlName="privacyDisclaimerRead"
        >
          * {{ "PRIVACY_POLICY_DISCLAIMER_1" | translate }}
          <a
            target="_blank"
            href="https://chabertonpartners.com/{{
              lang
            }}/privacy-policy-candidates/"
          >
            {{ "PRIVACY_POLICY_DISCLAIMER_2" | translate }}</a
          >
          {{ "PRIVACY_POLICY_DISCLAIMER_3" | translate }}
        </mat-checkbox>
      </div>
      <div class="required-disclaimer">
        <div>* {{ "ALL_REQUIRED_FIELDS" | translate }}</div>
        <div>** {{ "AT_LEAST_ONE_FIELD_REQUIRED" | translate }}</div>
      </div>
      <div class="button-container">
        <button
          class="main-button"
          type="submit"
          [disabled]="!profileForm.valid || !linkedInOrCv || disabled"
        >
          {{ "SUBMIT_APPLICATION" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
