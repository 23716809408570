import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { I18nService } from '@cosmobile/ng-i18n';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent implements OnInit {
  @Input() search: string | undefined = undefined;
  @Input() country: string | undefined = undefined;
  @Input() function: string | undefined = undefined;

  countriesUrl = 'https://cdn.cosmobile.net/customers/chaberton/countries.json';
  functionsUrl = 'https://cdn.cosmobile.net/customers/chaberton/functions.json';

  //Dati di default
  countries: { value: string; label: string }[] = [
    { value: 'Austria', label: 'country.AT' },
    { value: 'France', label: 'country.FR' },
    { value: 'Germany', label: 'country.DE' },
    { value: 'Italy', label: 'country.IT' },
    { value: 'Switzerland', label: 'country.CH' },
    { value: 'United Kingdom', label: 'country.GB' },
    { value: 'United States', label: 'country.US' },
    { value: 'Other Countries', label: 'country.OTH' },
  ];

  functions: { value: string; label: string }[] = [
    {
      value: 'Customer Service and Retail',
      label: 'function.customer_service_retail',
    },
    { value: 'Digital and Technology', label: 'function.digital_technology' },
    { value: 'Engineering', label: 'function.engineering' },
    { value: 'Finance', label: 'function.finance' },
    { value: 'Human Resources', label: 'function.human_resources' },
    {
      value: 'Legal, Tax and Compliance',
      label: 'function.legal_tax_compliance',
    },
    { value: 'Management and Strategy', label: 'function.management_strategy' },
    { value: 'Operations', label: 'function.operations' },
    {
      value: 'Sales, Marketing and Communications',
      label: 'function.sales_marketing_communications',
    },
    { value: 'Sustainability', label: 'function.sustainability' },
  ];
  loaded: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private i18nService: I18nService,
    private http: HttpClient
  ) {}

  searchForm = this.fb.group({
    countryInput: [''],
    functionInput: [''],
    searchInput: [''],
  });

  ngOnInit(): void {
    if (this.search) {
      this.searchForm.patchValue({ searchInput: this.search });
    }
    if (this.country) {
      this.searchForm.patchValue({ countryInput: this.country });
    }
    if (this.function) {
      this.searchForm.patchValue({ functionInput: this.function });
    }
    this.init();
  }

  async init() {
    this.countries = await this.getJsonData(this.countriesUrl);
    this.functions = await this.getJsonData(this.functionsUrl);
    this.loaded = true;
  }

  onSubmit() {
    const languages = this.i18nService.getLanguages();
    const currentLanguage = languages.find((el) => el.current);
    let queryParams = {};
    if (this.searchForm.value.countryInput) {
      queryParams = {
        ...queryParams,
        country: this.searchForm.value.countryInput,
      };
    }
    if (this.searchForm.value.functionInput) {
      queryParams = {
        ...queryParams,
        function: this.searchForm.value.functionInput,
      };
    }
    if (this.searchForm.value.searchInput) {
      queryParams = {
        ...queryParams,
        search: this.searchForm.value.searchInput,
      };
    }
    this.router.navigate([currentLanguage?.iso, 'applications'], {
      queryParams: queryParams,
    });
  }

  async getJsonData(url: string): Promise<{ value: string; label: string }[]> {
    return this.http.get<{ value: string; label: string }[]>(url).toPromise();
  }
}
