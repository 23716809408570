<div class="hero-banner-container">
  <img
    class="background-image"
    src="assets/images/banner_new.jpg"
    alt="{{ 'COVER_IMAGE' | translate }}"
  />
  <div class="texts-container">
    <h2 class="main-title">{{ "OPEN_POSITIONS" | translate }}</h2>
    <loader [loaded]="loaded">
      <form
        class="form-container"
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-row first">
          <div class="mat-form-field-search-wrapper">
            <div class="field-label">{{ "COUNTRY" | translate }}</div>
            <mat-form-field
              class="mat-form-field"
              appearance="legacy"
              floatLabel="never"
            >
              <mat-label>{{ "COUNTRY_PLACEHOLDER" | translate }}</mat-label>
              <mat-select
                formControlName="countryInput"
                (selectionChange)="onSubmit()"
              >
                <mat-option [value]="null">-</mat-option>
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.value"
                >
                  {{ country.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row second">
          <div class="mat-form-field-search-wrapper">
            <div class="field-label">{{ "FUNCTION" | translate }}</div>
            <mat-form-field
              class="mat-form-field"
              appearance="legacy"
              floatLabel="never"
            >
              <mat-label>{{ "FUNCTION_PLACEHOLDER" | translate }}</mat-label>
              <mat-select
                formControlName="functionInput"
                (selectionChange)="onSubmit()"
              >
                <mat-option [value]="null">-</mat-option>
                <mat-option
                  *ngFor="let function of functions"
                  [value]="function.value"
                >
                  {{ function.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row third">
          <div class="mat-form-field-search-wrapper">
            <div class="field-label">{{ "JOB_LOOKING_FOR" | translate }}</div>
            <mat-form-field
              class="mat-form-field"
              appearance="legacy"
              floatLabel="never"
            >
              <mat-label>{{
                "JOB_LOOKING_FOR_PLACEHOLDER" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="searchInput"
                (blur)="onSubmit()"
              />

              <button
                matSuffix
                class="submit-button-mobile"
                type="submit"
                [disabled]="!searchForm.valid"
              >
                <fa-icon name="search"></fa-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <button
          class="main-button submit-button submit-button-desktop"
          type="submit"
          [disabled]="!searchForm.valid"
        >
          {{ "FIND_JOB" | translate }}
        </button>
      </form>
    </loader>
  </div>
</div>
