
<div class="bigger-container">
  <div class="title-form-container">
    <div class="title-container">
      <h2>{{'SUBMIT_APPLICATION' | translate}}</h2>
    </div>
    <div class="form-container">
      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="first-row form-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="firstName">{{'FIRST_NAME' | translate}}*</mat-label>
            <input
              matInput
              id="firstName"
              type="text"
              formControlName="firstName"
            />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="lastName">{{'LAST_NAME' | translate}}*</mat-label>
            <input
              matInput
              id="lastName"
              type="text"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
        <div class="second-row form-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="emailAddress">Email*</mat-label>
            <input matInput id="emailAddress" type="text" formControlName="emailAddress" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="mobilePhone" required>{{'CELL_NUMBER' | translate}}*</mat-label>
            <input
              matInput
              id="mobilePhone"
              type="text"
              formControlName="mobilePhone"
            />
          </mat-form-field>
          <mat-form-field *ngIf="false" class="example-full-width" appearance="fill">
            <mat-label for="homePhone">{{'PHONE_NUMBER' | translate}}</mat-label>
            <input
              matInput
              id="homePhone"
              type="text"
              formControlName="homePhone"
            />
          </mat-form-field>
        </div>
        <div *ngIf="false" class="third-row form-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="notes">{{'NOTES' | translate}}</mat-label>
            <textarea
              matInput
              id="notes"
              type="text"
              rows="4"
              formControlName="notes"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="fourth-row form-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="linkedInUrl">{{'LINKEDIN' | translate}}**</mat-label>
            <input
              matInput
              id="linkedInUrl"
              type="text"
              formControlName="linkedInUrl"
            />
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label for="file">{{'RESUME_LETTER' | translate}}**</mat-label>
            <ngx-mat-file-input
              matInput
              id="file"
              formControlName="file"
              type="file"
              name="upload"
              accept="application/pdf,application/vnd.ms-excel"
            ></ngx-mat-file-input>
          </mat-form-field>
        </div>
        <div class="privacy-container form-row">
          <mat-checkbox type="checkbox" required formControlName="privacyDisclaimerRead">
            <span>
              * {{'PRIVACY_POLICY_DISCLAIMER_1' | translate}} <a target="_blank" href="https://chabertonpartners.com/{{lang}}/privacy-policy/"> {{'PRIVACY_POLICY_DISCLAIMER_2' | translate}}</a> {{'PRIVACY_POLICY_DISCLAIMER_3' | translate}}</span
            >
          </mat-checkbox>

          <mat-checkbox type="checkbox" required formControlName="privacyDisclaimerAccepted">
            <span>
              * {{'PRIVACY_POLICY_CONSENT' | translate}}
              <a href="mailto:dataprivacy@chabertonpartners.com">dataprivacy@chabertonpartners.com</a>.</span
            >
          </mat-checkbox>
        </div>
        <div class="required-disclaimer">
          <div> * {{'ALL_REQUIRED_FIELDS' | translate}} </div>
          <div> ** {{'AT_LEAST_ONE_FIELD_REQUIRED' | translate}} </div>
        </div>
        <div class="button-container form-row">
          <button
            class="main-button"
            type="submit"
            [disabled]="!profileForm.valid || !linkedInOrCv || disabled"
          >
            {{'SUBMIT_APPLICATION' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>