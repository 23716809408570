<ng-container *ngIf="!sent">
  <app-hero-banner></app-hero-banner>
  <ng-container *ngIf="advertisement">
    <div class="application-container">
      <app-article-button
        [full]="true"
        [advertisement]="advertisement"
      ></app-article-button>
    </div>
    <app-form (onSend)="onSend()" [advertisement]="advertisement"></app-form>
  </ng-container>
</ng-container>
<ng-container *ngIf="sent">
  <div class="thank-you-banner"></div>
  <div class="application-container">
    <app-thank-you></app-thank-you>
  </div>
</ng-container>
