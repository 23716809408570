<div class="article-button-container">
  <div class="article-container">
    <div class="title-container">
      <h2>{{advertisement.title}}</h2>
      <div *ngIf="advertisement.subtitle" class="subtitle-container">
        <h2>{{advertisement.subtitle}}</h2>
      </div>
    </div>
    <div class="date-intro-container">
      <div *ngIf="!full" class="intro-container">
        <div class="description-container">
          <p>
            {{advertisement.summary}}
          </p>
        </div>
      </div>
      <div *ngIf="advertisement.age" class="date-container">
        <p>{{'PUBLISHED' | translate}} <strong>{{advertisement.age}} {{'DAYS_AGO' | translate}}</strong></p>
      </div>
      <div *ngIf="full" class="intro-container">
        <div class="title-container">
          <h3>{{'SUMMARY' | translate}}</h3>
        </div>
        <div class="description-container">
          <p>
            {{advertisement.summary}}
          </p>
        </div>
      </div>
    </div>
    
    <ng-container *ngIf="full">
      <div class="details-container">
        <div class="title-container">
          <h3>{{'JOB_DESCRIPTION' | translate}}</h3>
        </div>
        <div class="inner-html" [innerHtml]="advertisement.description">
        </div>
      </div>
      <div class="end-banner-wrapper-desktop">
        <ng-container *ngTemplateOutlet="endBanner"></ng-container>
      </div>
    </ng-container>
  </div>  
  <div *ngIf="full" class="end-banner-wrapper-mobile">
    <ng-container *ngTemplateOutlet="endBanner"></ng-container>
  </div>
  <!-- <div class="buttons-container"> -->
    <a *ngIf="full" class="main-button main-button--anchor" (click)="scroll('scrollContent')">{{'APPLY' | translate}}</a>
    <a *ngIf="!full" class="main-button" [routerLink]="['/', lang, 'application', advertisement.id]">{{'APPLY' | translate}}</a>
    <a *ngIf="false && full" class="share-button" href="/"
      ><fa-icon [icon]="faShare" light></fa-icon
    ></a>
  <!-- </div> -->
</div>
<div id="scrollContent"></div>

<ng-template #endBanner>
  <div class="end-banner">
    <div *ngIf="advertisement.reference" class="first-voice">
      <div class="details-title-container">
        <p>{{'REF' | translate}}</p>
      </div>
      <div class="subtitle-container">
        <p>{{advertisement.reference}}</p>
      </div>
    </div>
    <div *ngIf="advertisement.employmentType" class="second-voice">
      <div class="details-title-container">
        <p>{{'EMPLOYMENT_TYPE' | translate}}</p>
      </div>
      <div class="subtitle-container">
        <p>{{advertisement.employmentType}}</p>
      </div>
    </div>
    <div *ngIf="advertisement.industry" class="third-voice">
      <div class="details-title-container">
        <p>{{'INDUSTRY' | translate}}</p>
      </div>
      <div class="subtitle-container">
        <p>{{advertisement.industry}}</p>
      </div>
    </div>
  </div>
</ng-template>