<div *ngIf="advertisements" class="sector-container">
  <div *ngIf="country || function || search" class="header">
    <h2 *ngIf="limit">{{ "NEW_JOB_OFFERS" | translate }}</h2>
    <ng-container *ngIf="!limit">
      <!-- <ng-container *ngIf="!(country || function || search)">
        <h2>{{ "ALL_JOB_OFFERS" | translate }}</h2></ng-container
      > -->
      <ng-container *ngIf="country || function || search">
        <h2 *ngIf="advertisements && advertisements.length > 0">
          {{ "SEARCH_RESULTS" | translate }}
        </h2>
        <h2 *ngIf="!advertisements || advertisements.length == 0">
          {{ "NO_RESULTS" | translate }}
        </h2>
      </ng-container>
    </ng-container>
    <a *ngIf="limit" [routerLink]="['/', lang, 'applications']">
      <span>{{ "READ_ALL" | translate }}</span>
      <fa-icon name="arrow-right" solid></fa-icon>
    </a>
  </div>
  <div class="main">
    <ng-container *ngFor="let advertisement of advertisements; let last = last">
      <app-article-button [advertisement]="advertisement"></app-article-button>
      <div *ngIf="!last" class="separator"></div>
    </ng-container>
  </div>
  <div class="footer">
    <a *ngIf="limit" [routerLink]="['/', lang, 'applications']">
      <span>{{ "READ_ALL" | translate }}</span>
      <fa-icon name="arrow-right" solid></fa-icon>
    </a>
  </div>
</div>
