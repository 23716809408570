import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../services/api.service';
import { I18nService } from '@cosmobile/ng-i18n';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.scss'],
})
export class SectorComponent implements OnInit, OnChanges {
  faCoffee = faCoffee;
  faArrowRight = faArrowRight;

  advertisements: any
  allAdvertisements: any
  @Input() limit: any
  @Input() search?: string
  lang?: string
  constructor(
    private apiService: ApiService,
    private i18n: I18nService
  ) {}

  async ngOnInit(): Promise<void> {
    const advertisements = await this.apiService.getAllAdvertisements() as any[]
    this.allAdvertisements = [...advertisements]
    this.filterAdvertisements(advertisements)
    this.lang = this.i18n.getLanguages().find(el => el.current)?.iso
  }
  async ngOnChanges() {
    if(this.allAdvertisements) {
      const advertisements = [...this.allAdvertisements]
      this.filterAdvertisements(advertisements)
    }
  }

  public filterAdvertisements(advertisements: any[]) {

    if (this.search) {
      this.advertisements = this.limit ? advertisements.slice(0, this.limit) : advertisements
      for (let searchKey of this.search.split(' ')) {
        searchKey = searchKey.trim()
        const regex = new RegExp( searchKey, "i");
        if (searchKey) {
          this.advertisements = this.advertisements?.filter( (el: any) => regex.test(el.title) || regex.test(el.summary)) || []
        }
      }
    } else {
      this.advertisements = this.limit ? advertisements.slice(0, this.limit) : advertisements
    }
  }
}
