import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  advertisement: any;
  sent = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.advertisement = await this.apiService.getAdvertisement(params.id);
      }
    });
  }

  onSend() {
    this.sent = true;
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 5000);
  }

  ngOnInit(): void {}
}
