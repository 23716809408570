import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { I18nService } from '@cosmobile/ng-i18n';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { GtagService } from '@cosmobile/ng-gtag';

function fullNameValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  if (control.value && control.value.split(' ').length < 2) {
    return { fullNameInvalid: true };
  }
  return null;
}
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Output() onSend = new EventEmitter<boolean>();
  @Input() advertisement: any;
  disabled = false;
  edited = false;
  linkedInOrCv = false;
  lang = this.i18n.getLanguages().find((el) => el.current)?.iso || 'it';
  profileForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.required]],
    lastName: ['', [Validators.required, Validators.required]],
    emailAddress: ['', [Validators.required, Validators.email]],
    mobilePhone: ['', Validators.required],
    homePhone: [''],
    notes: [''],
    linkedInUrl: [''],
    file: [''],
    file2: [''],
    privacyDisclaimerRead: ['', Validators.required],
    privacyDisclaimerAccepted: [true, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private i18n: I18nService,
    private gtagService: GtagService
  ) {
    this.profileForm.valueChanges.subscribe((formValue) => {
      if (!this.edited) {
        this.edited = true;
        this.gtagOnEdit();
      }
      if (formValue.linkedInUrl || formValue.file || formValue.file2) {
        this.linkedInOrCv = true;
      } else {
        this.linkedInOrCv = false;
      }
    });
  }

  async onSubmit() {
    //console.warn(this.profileForm.value);
    try {
      this.disabled = true;
      await this.apiService.apply(
        this.advertisement.assignmentId,
        this.profileForm.value
      );
      this.onSend.emit(true);
      this.gtagOnSubmit();
    } catch (e: any) {
      const msg = e.message || 'Si è verificato un errore. Riprovare più tardi';
      alert(msg);
      this.disabled = false;
    }
  }

  async gtagOnSubmit() {
    this.gtagService.eventTrack('application_form', 'application', 'submit');
  }

  async gtagOnEdit() {
    this.gtagService.eventTrack('application_form', 'application', 'edit');
  }

  ngOnInit(): void {}
}
