<header #header [class.header__shrinked]="shrinkHeader">
  <div class="header-wrapper">
    <div class="header-left">
      <a target="_blank" href="https://chabertonpartners.com/{{lang}}">
        <img src="assets/images/logo_new.png" alt="" />
      </a>
    </div>
    <div class="header-right-desktop">
      <a [routerLink]="['/', lang]"> {{'OPEN_POSITIONS' | translate}} </a>
      <div class="languages-container">
        <app-lang-menu></app-lang-menu>
      </div>
    </div>
    <div class="header-right-mobile">
      <fa-icon *ngIf="false" [icon]="faBars" light></fa-icon>
      <app-lang-menu></app-lang-menu>
    </div>
  </div>

</header>
