import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticleButtonComponent } from './blocks/article-button/article-button.component';
import { HeaderComponent } from './blocks/header/header.component';
import { HeroBannerComponent } from './blocks/hero-banner/hero-banner.component';
import { SectorComponent } from './blocks/sector/sector.component';
import { FontawesomeModule } from '@cosmobile/ng-fontawesome';
import { FormComponent } from './blocks/form/form.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangMenuComponent } from './blocks/lang-menu/lang-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ThankYouComponent } from './blocks/thank-you/thank-you.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HomeComponent } from './main-component/home/home.component';
import { ApplicationComponent } from './main-component/application/application.component';
import {MatSelectModule} from '@angular/material/select';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CookiebotModule } from '@cosmobile/ng-cookiebot';
import { FrontendUtilsModule } from '@cosmobile/ng-frontend-utils';
import { environment } from '../environments/environment';
import { I18nModule } from '@cosmobile/ng-i18n';
import { UniversalInterceptor } from './universal.interceptor';
import { GtagModule } from '@cosmobile/ng-gtag'


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeroBannerComponent,
    ArticleButtonComponent,
    SectorComponent,
    FormComponent,
    LangMenuComponent,
    ThankYouComponent,
    HomeComponent,
    ApplicationComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    I18nModule,
    MatInputModule,
    MatCheckboxModule,
    MaterialFileInputModule,
    MatSelectModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    RecaptchaV3Module,
    GtagModule.forRoot({
      fromEnv: true,
    }),
    FrontendUtilsModule.forRoot({
      environment: environment
    }),
    CookiebotModule.forRoot({ fromEnv: true }),
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LezJnkeAAAAALu1v84cR_EwUSNIrfJ3LABZenlH" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
