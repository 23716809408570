import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { I18nService } from '@cosmobile/ng-i18n';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.scss'],
})
export class SectorComponent implements OnInit, OnChanges {
  @Input() limit: any;
  @Input() search?: string;
  @Input() country?: string;
  @Input() function?: string;
  advertisements: any;
  allAdvertisements: any;
  lang?: string;

  constructor(private apiService: ApiService, private i18n: I18nService) {}

  async ngOnInit(): Promise<void> {
    this.allAdvertisements = await this.apiService.getAllAdvertisements();
    this.filterAdvertisements(this.allAdvertisements);
    this.lang = this.i18n.getLanguages().find((el) => el.current)?.iso;
  }

  async ngOnChanges() {
    if (!this.allAdvertisements) {
      this.allAdvertisements = await this.apiService.getAllAdvertisements();
    }
    this.filterAdvertisements(this.allAdvertisements);
  }

  public filterAdvertisements(advertisements: any[]) {
    if (this.search || this.country || this.function) {
      this.advertisements = [...this.allAdvertisements];
      if (this.search) {
        for (let searchKey of this.search.split(' ')) {
          searchKey = searchKey.trim();
          const regex = new RegExp(searchKey, 'i');
          if (searchKey) {
            this.advertisements =
              this.advertisements?.filter(
                (el: any) => regex.test(el.title) || regex.test(el.summary)
              ) || [];
          }
        }
      }
      if (this.country) {
        this.advertisements =
          this.advertisements?.filter((el: any) =>
            el.countries?.includes(this.country)
          ) || [];
      }
      if (this.function) {
        this.advertisements =
          this.advertisements?.filter((el: any) =>
            el.functions?.includes(this.function)
          ) || [];
      }
    } else {
      this.advertisements = this.limit
        ? this.allAdvertisements.slice(0, this.limit)
        : this.allAdvertisements;
    }
  }
}
