import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { I18nService } from '@cosmobile/ng-i18n';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent implements OnInit {
  @Input() search: string | undefined = ''
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private i18nService: I18nService
    ) {}

  searchForm = this.fb.group({
    searchInput: ['']
  });

  ngOnInit(): void {
    // this.route.queryParams
    //   .subscribe(params => {
    //     console.log(params)
    //     this.searchInput = params.search;
    //     this.searchForm.patchValue({searchInput: this.searchInput})
    //   }
    // );

    if (this.search){
      this.searchForm.patchValue({searchInput: this.search})
    }
  }
  onSubmit() {
    const languages = this.i18nService.getLanguages()
    const currentLanguage = languages.find( el => el.current)
    this.router.navigate([ currentLanguage?.iso, 'applications'], { queryParams: { search: this.searchForm.value.searchInput }} )
  }
}
