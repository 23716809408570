import { Component, OnInit, Inject } from '@angular/core';
import { I18nService, Language } from '@cosmobile/ng-i18n';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
})
export class LangMenuComponent implements OnInit {

  availableLanguages: Language[] = []
  selected = 'it'

  constructor(
    private i18n: I18nService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document) {

    const availableLanguages = i18n.getLanguages()
    this.availableLanguages = availableLanguages.sort( (a, b) => {return a.current ? -1 : 1})
  }

  changeLanguage(event: any) {
    this.document.body.classList.remove('visible')
    const selectedLang = event.srcElement.id
    if(selectedLang) {
      this.i18n.setCurrentLanguage(selectedLang)
    }
    //setTimeout(() => this.document.body.classList.add('visible'), 100)

    // const availableLanguages = this.i18n.getLanguages()
    // console.log("sorting", {langs: this.availableLanguages})
    // this.availableLanguages = availableLanguages.sort( (a, b) => {return a.current ? -1 : 1})
  }

  ngOnInit(): void {}
}
