<div class="article-button-container" [class.full]="full">
  <div class="article-container">
    <div class="title-container">
      <h2>{{ advertisement.title }}</h2>
      <div *ngIf="advertisement.subtitle" class="subtitle-container">
        <h3>{{ advertisement.subtitle }}</h3>
      </div>
    </div>
    <div class="date-intro-container">
      <div *ngIf="advertisement.age > 1" class="date-container">
        {{ "PUBLISHED" | translate }}
        <strong>{{ advertisement.age }} {{ "DAYS_AGO" | translate }}</strong>
      </div>
      <div *ngIf="advertisement.age == 1" class="date-container">
        {{ "PUBLISHED" | translate }}
        <strong>{{ "ONE_DAY_AGO" | translate }}</strong>
      </div>
      <div *ngIf="advertisement.age == 0" class="date-container">
        <strong>{{ "PUBLISHED_TODAY" | translate }}</strong>
      </div>
      <div *ngIf="!full" class="intro-container">
        <div class="description-container">
          {{ advertisement.summary }}
        </div>
      </div>
      <div *ngIf="full" class="intro-container">
        <div class="title-container">
          <h3>{{ "SUMMARY" | translate }}</h3>
        </div>
        <div class="description-container">
          {{ advertisement.summary }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="full">
      <div class="details-container">
        <div class="title-container">
          <h3>{{ "JOB_DESCRIPTION" | translate }}</h3>
        </div>
        <div class="inner-html" [innerHtml]="advertisement.description"></div>
      </div>
      <ng-container *ngTemplateOutlet="endBanner"></ng-container>
    </ng-container>
  </div>
  <a
    *ngIf="full"
    class="main-button main-button--anchor"
    (click)="scroll('scrollContent')"
    >{{ "APPLY" | translate }}</a
  >
  <a
    *ngIf="!full"
    class="main-button"
    [routerLink]="['/', lang, 'application', advertisement.id]"
    >{{ "APPLY" | translate }}</a
  >
</div>
<div id="scrollContent" class="scrollContent"></div>

<ng-template #endBanner>
  <div class="end-banner">
    <div *ngIf="advertisement.reference" class="end-banner__section">
      <div class="end-banner__section__title">
        {{ "REF" | translate }}
      </div>
      <div class="end-banner__section__subtitle">
        {{ advertisement.reference }}
      </div>
    </div>
    <div *ngIf="advertisement.employmentType" class="end-banner__section">
      <div class="end-banner__section__title">
        {{ "EMPLOYMENT_TYPE" | translate }}
      </div>
      <div class="end-banner__section__subtitle">
        {{ advertisement.employmentType }}
      </div>
    </div>
    <div *ngIf="advertisement.industry" class="end-banner__section">
      <div class="end-banner__section__title">
        {{ "INDUSTRY" | translate }}
      </div>
      <div class="end-banner__section__subtitle">
        {{ advertisement.industry }}
      </div>
    </div>
  </div>
</ng-template>
