import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@cosmobile/ng-i18n';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  limit: number | undefined
  search?: string
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private i18n: I18nService
  ) {
    route.data.subscribe(  data => {
      if(data && data.limit) {
        this.limit = data.limit
      }
    })

    this.route.params.subscribe(async (params) => {
      if (params.lang) {
        this.translate.use(params.lang)
        this.i18n.setCurrentLanguage(params.lang)
      }
    })

  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.search = params.search
      }
    )
  }

}
