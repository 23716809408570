import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { I18nService } from '@cosmobile/ng-i18n';

@Component({
  selector: 'app-article-button',
  templateUrl: './article-button.component.html',
  styleUrls: ['./article-button.component.scss'],
})
export class ArticleButtonComponent implements OnInit {
  @ViewChild('scrollContent') private myScrollContainer!: ElementRef;
  @Input() advertisement: any;
  @Input() full = false;

  lang?: string;

  constructor(private i18n: I18nService) {}

  ngOnInit(): void {
    for (let separator of ['-', '|']) {
      if (this.advertisement.title.includes(separator)) {
        const splitting = this.advertisement.title.split(separator);
        this.advertisement.title = splitting[0];
        this.advertisement.subtitle = splitting[1];
        break;
      }
    }
    const now = dayjs();
    if (this.advertisement.datePublished) {
      const age = now.diff(this.advertisement.datePublished, 'd');
      if (age >= 0) {
        this.advertisement.age = age;
      } else {
        this.advertisement.age = -1; // future date
      }
    } else {
      this.advertisement.age = -1;
    }
    this.lang = this.i18n.getLanguages().find((el) => el.current)?.iso;
  }
  scroll(id: string) {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
