import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { TransferState } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl;
  constructor(
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService
  ) //@Inject(PLATFORM_ID) private platformId,
  //private transferState: TransferState,
  {
    this.baseUrl = environment.apiUrl.endsWith('/')
      ? environment.apiUrl
      : environment.apiUrl + '/';
  }

  async getAllAdvertisements() {
    try {
      this.document.body.classList.remove('visible');
      return this.http
        .get(this.baseUrl + 'recruiting/advertisements')
        .toPromise();
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setTimeout(() => this.document.body.classList.add('visible'), 100);
    }
  }

  async getAdvertisement(id: string) {
    try {
      this.document.body.classList.remove('visible');
      return await this.http
        .get(`${this.baseUrl}recruiting/advertisements/${id}`)
        .toPromise();
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setTimeout(() => this.document.body.classList.add('visible'), 100);
    }
  }

  async apply(id: string, body: any) {
    body.privacyDisclaimerRead = body.privacyDisclaimerRead ? 'true' : 'false';
    body.privacyDisclaimerAccepted = body.privacyDisclaimerAccepted
      ? 'true'
      : 'false';
    const fData = new FormData();
    for (let key of Object.keys(body)) {
      //console.log('appending', {val: body[key]})
      if (key === 'file' || key === 'file2') {
        if (body[key] && body[key]._files[0]) {
          const file = body[key]._files[0];
          //10MB // TODO: parametrizzare
          const maxFileSizeMB = 10;
          if (file.size > 1024 * 1024 * maxFileSizeMB) {
            throw new Error(
              this.translateService.instant('FILE_TOO_LARGE', {
                MAX: maxFileSizeMB,
              })
            );
          }
          fData.append(key, file);
        }
      } else {
        fData.append(key, body[key]);
      }
    }
    try {
      const subscription = await this.recaptchaV3Service
        .execute('apply')
        .toPromise();
      const headers = {
        enctype: 'multipart/form-data',
        recaptcha: subscription,
      };
      return this.http
        .post(`${this.baseUrl}recruiting/assignments/${id}/apply`, fData, {
          headers,
        })
        .toPromise();
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
