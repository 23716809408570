<div *ngIf="advertisements" class="sector-container">
  <div class="header">
    <h2 *ngIf="limit">{{'NEW_JOB_OFFERS' | translate}}</h2>
    <h2 *ngIf="!limit && !search">{{'ALL_JOB_OFFERS' | translate}}</h2>
    <h2 *ngIf="!limit && search && advertisements && advertisements.length">{{'SEARCH_RESULTS' | translate}}</h2>
    <h2 *ngIf="!limit && search && (!advertisements || !advertisements.length)">{{'NO_RESULTS' | translate}}</h2>
    <a *ngIf="limit" [routerLink]="['/', lang, 'applications']">{{'READ_ALL' | translate}} <span style="display:inline-block; width: 14px;"><fa-icon [icon]="faArrowRight" light></fa-icon></span></a>
  </div>
  <div class="main">
    <ng-container *ngFor="let advertisement of advertisements">
      <app-article-button [advertisement]="advertisement"></app-article-button>
      <div class="separator"></div>
    </ng-container>
  </div>
  <div class="footer">
    <a *ngIf="limit" [routerLink]="['/', lang, 'applications']">{{'READ_ALL' | translate}} <span style="display:inline-block; width: 14px;"><fa-icon [icon]="faArrowRight" light></fa-icon></span></a>
  </div>
</div>
