import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '@cosmobile/ng-i18n';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  faBars = faBars;

  shrinkHeader = false;

  lang?: string
  @ViewChild('header', { static: false }) header!: ElementRef;

  constructor(
    private translate: TranslateService,
    private i18n: I18nService,
    @Inject(PLATFORM_ID) private platform: object
    ) {
  }


  @HostListener('window:scroll')
  scrollEvent(): void {
    if (isPlatformBrowser(this.platform)) {
      this.shrinkHeader = !!window.pageYOffset;
    // this.header.nativeElement.style.backgroundColor = 'red';
    }
  }

  ngOnInit(): void {
    const languages = this.i18n.getLanguages()
    this.lang = languages.find(el => el.current)?.iso

  }
}
