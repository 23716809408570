import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { I18nService } from '@cosmobile/ng-i18n';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Chaberton';
  constructor(
    private i18n: I18nService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.i18n.initLanguages(
      [
        {
          iso: 'it',
          name: 'italian',
          default: true,
        },
        {
          iso: 'en',
          name: 'english'
        },
        {
          iso: 'de',
          name: 'german',
        },
        {
          iso: 'fr',
          name: 'french',
        }
      ]
    )
    //this.translate.use(languages.find(el => el.current)?.iso)
    // this language will be used as a fallback when a translation isn't found in the current language
    //translate.setDefaultLang('it')
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    //translate.use('it')
  }

  onActivate(e: any) {
    if (isPlatformBrowser(this.platform)) {
      window.scroll(0, 0)
    }
  }

}
