<app-hero-banner
  [search]="search"
  [function]="function"
  [country]="country"
></app-hero-banner>
<app-sector
  [limit]="limit"
  [search]="search"
  [function]="function"
  [country]="country"
></app-sector>
