<div class="hero-banner-container">
  <img class="background-image" src="assets/images/banner_new.jpg" alt="" />
  <div class="texts-container">
    <div class="pre-title-container">
      <p>CHABERTON PROFESSIONALS</p>
    </div>
    <div class="title-container">
      <h2>{{ "OPEN_POSITIONS" | translate }}</h2>
    </div>
    <div class="search-title-mobile">{{'JOB_LOOKING_FOR' | translate}}</div>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="first-row form-row form-row--search">
        <div class="mat-form-field-search-wrapper">
          <div class="search-title">{{'JOB_LOOKING_FOR' | translate}}</div>
          <mat-form-field class="mat-form-field--search" appearance="fill">
            <mat-label for="searchInput">{{'SEARCH_PLACEHOLDER' | translate}}</mat-label>
            <input
              matInput
              id="searchInput"
              type="text"
              place
              formControlName="searchInput"
            />
          </mat-form-field>
          <button class="icon-search-button" type="submit" [disabled]="!searchForm.valid">
            <!-- <img src="/assets/images/search-light.svg"> -->
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"></path></svg>
          </button>
        </div>
        <fa-icon name="search"></fa-icon>
        <button
          class="main-button main-button--search"
          type="submit"
          [disabled]="!searchForm.valid"
        >
          {{'FIND_JOB' | translate}}
        </button>
      </div>
    </form>

  </div>
</div>
