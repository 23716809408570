<div class="thank-you-container">
  <div class="title-container">
    <h2>{{ "THANK_YOU" | translate }}</h2>
  </div>
  <div class="subtitle-container">
    <p>
      {{ "THANK_YOU_MESSAGE.FIRST_PARAGRAPH" | translate }}
    </p>
    <p>
      {{ "THANK_YOU_MESSAGE.SECOND_PARAGRAPH" | translate }}
    </p>
    <p>
      {{ "THANK_YOU_MESSAGE.THIRD_PARAGRAPH" | translate }}
    </p>
  </div>
  <div class="image-container">
    <img src="assets/images/stretta.png" alt="" />
  </div>
</div>
